<template>
  <card-component>
    <div class="columns">
      <div class="column is-8-tablet">
        <div class="map-wrapper">
          <gmap-map
            :center="center"
            :zoom="4.7"
            map-type-id="terrain"
            style="height: 450px"
          >
            <gmap-info-window
              :options="{pixelOffset: {width: 0,height: -35}}"
              :position="infoWindowPos"
              :opened="infoWindowOpen"
              @closeclick="infoWindowOpen=false">
              <div class="has-text-weight-bold">{{ infoContent.header }}</div>
              <div>{{ infoContent.body }}</div>
            </gmap-info-window>

            <gmap-marker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              :clickable="true"
              :draggable="false"
              @click="handleClickMarker(index)"
            />
          </gmap-map>

          <div class="map-info" v-if="showFarmInfo">
            <div class="card">
              <div class="card-content px-3 py-2">
                <div v-if="!farmInfoLoading && farmInfo">
                  <div class="is-flex is-justify-content-space-between is-align-items-center mb-2">
                    <h4 class="has-text-weight-bold mb-0">{{ farmInfo.name }}</h4>
                    <div @click="showFarmInfo = false">
                      <b-icon class="cursor-pointer has-text-grey" icon="close"/>
                    </div>
                  </div>
                  <div class="columns is-gapless mb-2">
                    <div class="column">Owner</div>
                    <div class="column">:
                      <span class="has-text-weight-bold cursor-pointer is-underline has-text-primary ml-2"
                            @click="clickToCopy" title="Click to Copy to Clipboard">
                    {{ farmInfo.user ? farmInfo.user.username : farmInfo.user_id }}
                  </span>
                    </div>
                  </div>
                  <div class="columns is-gapless mb-2">
                    <div class="column">Phone Number</div>
                    <div class="column">:
                      <span class="has-text-weight-bold cursor-pointer is-underline has-text-primary ml-2"
                            @click="clickToCopy" title="Click to Copy to Clipboard">
                    {{ farmInfo.user ? farmInfo.user.phone : '' }}
                  </span>
                    </div>
                  </div>
                  <div class="columns is-gapless mb-2">
                    <div class="column">Email</div>
                    <div class="column">:
                      <span class="has-text-weight-bold cursor-pointer is-underline has-text-primary ml-2"
                            @click="clickToCopy" title="Click to Copy to Clipboard">
                    {{ farmInfo.user ? farmInfo.user.email : '' }}
                  </span>
                    </div>
                  </div>
                  <div class="columns is-gapless mb-2">
                    <div class="column">Total Block</div>
                    <div class="column">: <span class="has-text-weight-bold ml-2">{{ farmInfo.info.block_count }}</span>
                    </div>
                  </div>
                  <div class="columns is-gapless mb-2">
                    <div class="column">Total Pond</div>
                    <div class="column">: <span class="has-text-weight-bold ml-2">{{ farmInfo.info.pond_count }}</span>
                    </div>
                  </div>
                  <div class="columns is-gapless">
                    <div class="column">Total User</div>
                    <div class="column">: <span class="has-text-weight-bold ml-2">{{ farmInfo.info.user_count }}</span>
                    </div>
                  </div>
                </div>
                <div class="loader my-2 ml-2" style="height: 25px; width: 25px;" v-else></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-4-tablet">
        <div style="max-height: 450px;overflow-y: auto" class="px-4" v-if="!farms.loading">
          <div class="is-flex is-justify-content-space-between mb-2" v-for="(item, key) in getTotalFarmByCountry"
               :key="key">
            <span class="has-text-weight-bold">{{ item.name }}</span>
            <span>{{ item.total }}</span>
          </div>
        </div>
        <div v-else class="is-flex is-justify-content-center is-align-items-center" style="height: 450px">
          <div class="loader mt-2" style="height: 25px; width: 25px;"></div>
        </div>
      </div>
    </div>
  </card-component>
</template>

<script>
/* eslint-disable */
import {mapState, mapActions} from 'vuex'
import CardComponent from "@/components/CardComponent";

export default {
  name: "BookFarmMap",
  components: {CardComponent},
  data: () => {
    return {
      markers: [],
      places: [],
      center: {lat: -2.5489, lng: 118.0149},
      currentPlace: null,
      infoContent: {
        header: null,
        body: null,
        footer: null
      },
      infoWindowPos: null,
      infoWindowOpen: null,
      markerMode: '',
      selectedFarm: null,
      farmInfo: null,
      farmInfoLoading: false,
      showFarmInfo: false,

    }
  },
  props: {
    defaultCenter: {
      type: Object
    }
  },
  watch: {
    markers: {
      deep: true,
      handler(value) {
        if (value[0]) this.$emit('update-geolocation', value[0].position)
      }
    },
    'farms.data': {
      deep: true,
      handler() {
        this.generateMarker()
      }
    }
  },
  computed: {
    ...mapState('farm', [
      'farms',
    ]),

    ...mapState('user', [
      'users',
    ]),

    getTotalFarmByCountry() {
      return this.farms.data.reduce((a, b) => {
        let name = b.city.toLowerCase()
          // .replace(/kota |kab |kabupaten |kab. |kab.|kec |kec.|kec. |kecamatan |kota|kabupaten|kecamatan/ig, '')
          .trim()
          .split(' ').map(e => e[0].toUpperCase() + e.substr(1)).join(' ')

        let find = a.find(e => e.name === name)

        if (find) find.total++
        else a.push({
          name: name,
          total: 1
        })

        return a
      }, []).sort((a, b) => a.name > b.name ? 1 : -1)
    },
  },
  methods: {
    ...mapActions('farm', [
      'getFarmInfo'
    ]),

    clickToCopy(e) {
      const el = document.createElement('textarea');
      el.value = e.target.innerText;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      this.$swal({
        icon: 'success',
        title: 'Copied to clipboard',
      })
    },

    async handleClickMarker(key) {
      this.showFarmInfo = true

      this.selectedFarm = key

      this.farmInfo = null

      let farm = this.farms.data[this.selectedFarm]

      if (farm) {
        this.farmInfoLoading = true

        farm.user = this.users.data.find(e => e.user_id === farm.user_id)

        farm.info = {
          block_count: 0,
          pond_count: 0,
          user_count: 0,
        }

        new Promise((resolve => {
          this.getFarmInfo(farm.farm_id).then(res => {
            resolve({
              farm_id: farm.farm_id,
              data: res
            })
          })
        })).finally(() => {
          this.farmInfoLoading = false
        }).then(res => {
          farm.info = res.data
          this.farmInfo = farm
        })
      }
    },
    async generateMarker() {
      await this.$gmapApiPromiseLazy();

      this.selectedFarm = null
      this.markers = []

      for (let i = 0; i < this.farms.data.length; i++) {
        let farm = this.farms.data[i]

        let location = farm.location.split(',')

        let place = {
          position: new google.maps.LatLng(location[0], location[1]),
        }

        this.markers.push({
          position: place.position,
          title: farm,
        })
      }
    },
  }
}
</script>
