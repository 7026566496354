<template>
  <card-component
    title="Ponds"
    icon="finance"
  >
    <b-loading :is-full-page="false" v-model="data.loading"></b-loading>

    <sg-tab v-model="activeTab"
            :items="['DAP', 'MAP', 'DPA', 'NOP Daily', 'NOP Monthly']"
            @input="getData"
            class="border-top-0 mb-5"/>

    <sg-tab v-model="chartType"
            :items="['Bar', 'Line']"
            type="button"
            class="border-top-0 mb-5"/>


    <div class="is-flex" v-if="!data.loading">
      <b-field>
        <b-datepicker v-if="!isMonthly"
                      placeholder="Select Date"
                      v-model="dailyDate"
                      locale="id-ID"
                      @input="getData"
                      range>
        </b-datepicker>
        <b-datepicker v-if="isMonthly"
                      type="month"
                      placeholder="Select Month"
                      v-model="monthlyDate"
                      locale="id-ID"
                      @input="getData"
                      range>
        </b-datepicker>
      </b-field>
    </div>

    <div v-if="barData">
      <bar-chart v-if="chartType === 'Bar'" :series="barData.series" :chart-options="barData.chartOptions"/>
      <line-chart v-else-if="chartType === 'Line'" :series="barData.series" :chart-options="barData.chartOptions"/>
    </div>
  </card-component>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import SgTab from "@/components/Sg/SgTab";
import BarChart from "@/components/Charts/BarChart";
import LineChart from "@/components/Charts/LineChart";
import {mapActions, mapState} from "vuex";

export default {
  name: "BookChartPonds",
  components: {LineChart, BarChart, SgTab, CardComponent},
  data: () => {
    return {
      dailyDate: [],
      monthlyDate: [],

      activeTab: 'DAP',
      chartType: 'Bar',
    }
  },
  computed: {
    ...mapState('farm', [
      'chart',
    ]),

    isMonthly() {
      return ['MAP', 'NOP Monthly'].includes(this.activeTab)
    },

    data() {
      return this.chart[this.activeTab.replace(' ', '_')]
    },

    barData() {
      let data = this.data.data

      if (data && data.length) {
        // let from = this.$timestamp(this.graph_date[0])
        // let to = this.$timestamp(this.graph_date[1])

        return {
          series: [{
            name: 'Ponds',
            type: 'area',
            data: data.map(e => {
              return {
                x: e.updated_at || e.created_at,
                y: e.total,
              }
            })
          }],
          chartOptions: {
            chart: {
              height: 350,
              type: 'bar',
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            fill: {
              type: 'solid',
              opacity: 1,
            },
            labels: data.map(e => e.size),
            markers: {
              size: 0
            },
            xaxis: {
              type: "datetime",
              labels: {
                formatter: (val, timestamp) => {
                  return this.$dateFormat(timestamp, this.isMonthly ? 'MMM YYYY' : 'DD MMM YYYY')
                }
              }
            },
            tooltip: {
              enabled: true,
              followCursor: true,
              x: {
                format: this.isMonthly ? 'MMM yyyy' : 'dd MMM yyyy',
              },
            }
          }
        }
      }
      return null
    }
  },
  created() {
    this.getData()
  },
  methods: {
    ...mapActions('farm', [
      'getDailyActivePonds',
      'getMonthlyActivePonds',
      'getDailyPondsActivity',
      'getDailyNewPonds',
      'getMonthlyNewPonds',
    ]),

    getData() {
      let from = this.$moment().set({date: 1, hour: 0, minute: 0, second: 0, millisecond: 0})
      let to = from.clone().add(1, 'month').subtract(1, 'ms')

      if (this.isMonthly) {
        from = this.$moment().set({date: 1, hour: 0, minute: 0, second: 0, millisecond: 0}).subtract(2, 'month')
        if (this.monthlyDate.length) {
          from = this.$moment(this.monthlyDate[0])
          to = this.$moment(this.monthlyDate[1])
        } else {
          this.monthlyDate = [
            new Date(from),
            new Date(to),
          ]
        }
      } else {
        if (this.dailyDate.length) {
          from = this.$moment(this.dailyDate[0])
          to = this.$moment(this.monthlyDate[1])
        } else {
          this.dailyDate = [
            new Date(from),
            new Date(to),
          ]
        }
      }

      switch (this.activeTab) {
        case 'DAP':
          return this.getDailyActivePonds({from, to})
        case 'MAP':
          return this.getMonthlyActivePonds({from, to})
        case 'DPA':
          return this.getDailyPondsActivity({from, to})
        case 'NOP Daily':
          return this.getDailyNewPonds({from, to})
        case 'NOP Monthly':
          return this.getMonthlyNewPonds({from, to})
      }
    },
  }
}
</script>
