<template>
  <div>
    <title-bar :title-stack="titleStack"/>
    <section class="section is-main-section">
      <book-farm-table class="mb-4"/>
      <book-farm-map/>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import BookMetrics from "@/components/Book/BookMetrics";
import BookChart from "@/components/Book/BookChart";
import SgTab from "@/components/Sg/SgTab";
import BookFarmTable from "@/components/Book/BookFarmTable";
import BookFarmMap from "@/components/Book/BookFarmMap";

export default {
  name: 'BookFarms',
  components: {
    BookFarmMap,
    BookFarmTable,
    TitleBar
  },
  data: () => {
    return {
      activeTab: 'Metrics',
    }
  },
  computed: {
    titleStack() {
      return ['SgaraBook', 'Farms']
    }
  },
}
</script>
