<template>
  <div>
    <book-chart-ponds/>
    <book-chart-farms/>
    <book-chart-disease/>
  </div>
</template>

<script>
import BookChartPonds from "@/components/Book/BookChartPonds";
import BookChartFarms from "@/components/Book/BookChartFarms";
import BookChartDisease from "@/components/Book/BookChartDisease";

export default {
  name: "BookChart",
  components: {
    BookChartDisease,
    BookChartFarms,
    BookChartPonds,
  },
}
</script>

<style scoped>

</style>
