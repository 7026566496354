<template>
  <div class="sg-tabs" :class="{'sg-tabs-button': type === 'button'}">
    <div class="is-flex" v-if="!type" :class="{
      'w-100': extended
    }">
      <div class="sg-tab" :class="{
       'sg-active': (value === (typeof item === 'object' ? item.value : item)),
       'is-flex-grow-1': extended
    }" v-for="(item, key) in items" :key="key" @click="$emit('input', typeof item === 'object' ? item.value : item)">
        {{ typeof item === 'object' ? item.label : item }}
      </div>
    </div>
    <div v-if="type === 'button'" class="scroll-x">
      <b-button :type="value === item ? activeClass : defaultClass" class="mr-2"
                v-for="(item, key) in items" :key="key" @click="$emit('input', item)">
        {{ item }}
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SgTab",
  props: {
    value: {},
    items: {},
    type: {
      type: String,
      default: null
    },
    activeClass: {
      type: String,
      default: 'is-danger is-light'
    },
    defaultClass: {
      type: String,
      default: 'is-text'
    },
    extended: Boolean,
  }
}
</script>

<style lang="scss">
.sg-tabs {
  display: flex;
  overflow-x: auto;

  .button {
    padding-left: 1em !important;
    padding-right: 1em !important;
    text-decoration: unset !important;
  }

  &:not(.sg-tabs-button) {
    border-top: 1px solid #E2E2E2;
    border-bottom: 1px solid #E2E2E2;
  }

  .sg-tab {
    font-weight: bold;
    color: #A3A5AA;
    cursor: pointer;
    position: relative;
    padding: 1rem .25rem;
    margin-right: 1.65rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    &:hover {
      color: #010101;
    }

    &.sg-active {
      color: #010101;

      &::before {
        position: absolute;
        content: '';
        width: 95%;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        height: 4px;
        background-color: #586479;
        border-radius: 10px 10px 0 0;
      }
    }
  }
}
</style>
