<template>
  <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "LineChart",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    series: {
      type: Array,
      default: () => {
        return [{
          name: 'TEAM A',
          type: 'area',
          data: [44, 55, 31, 47, 31, 43, 26, 41, 31, 47, 33]
        }]
      }
    },
    chartOptions: {
      type: Object,
      default: () => {
        return {
          chart: {
            height: 350,
            type: 'line',
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          fill: {
            type:'solid',
            opacity: 1,
          },
          labels: ['Dec 01', 'Dec 02','Dec 03','Dec 04','Dec 05','Dec 06','Dec 07','Dec 08','Dec 09 ','Dec 10','Dec 11'],
          markers: {
            size: 0
          },
          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y) {
                if(typeof y !== "undefined") {
                  return  y.toFixed(0) + " points";
                }
                return y;
              }
            }
          }
        }
      }
    },
  },
  data: () => {
    return {
      // series: [{
      //   name: 'TEAM A',
      //   type: 'area',
      //   data: [44, 55, 31, 47, 31, 43, 26, 41, 31, 47, 33]
      // }],
      // chartOptions: {
      //   chart: {
      //     height: 350,
      //     type: 'line',
      //   },
      //   stroke: {
      //     curve: 'smooth'
      //   },
      //   fill: {
      //     type:'solid',
      //     opacity: 1,
      //   },
      //   labels: ['Dec 01', 'Dec 02','Dec 03','Dec 04','Dec 05','Dec 06','Dec 07','Dec 08','Dec 09 ','Dec 10','Dec 11'],
      //   markers: {
      //     size: 0
      //   },
      //   yaxis: [
      //     {
      //       title: {
      //         text: 'Series A',
      //       },
      //     },
      //   ],
      //   tooltip: {
      //     shared: true,
      //     intersect: false,
      //     y: {
      //       formatter: function (y) {
      //         if(typeof y !== "undefined") {
      //           return  y.toFixed(0) + " points";
      //         }
      //         return y;
      //       }
      //     }
      //   }
      // },
    }
  }
}
</script>
