<template>
  <card-component
    title="Disease"
    icon="finance"
  >
    <b-loading :is-full-page="false" v-model="data.loading"></b-loading>

    <sg-tab v-model="activeTab"
            :items="['Sum of Death shrimp group by disease per month', 'Sum of Death shrimp group by province']"
            @input="getData"
            class="border-top-0 mb-5"/>

    <div class="is-flex">
      <b-datepicker type="month"
                    placeholder="Select Month"
                    v-model="monthlyDate"
                    locale="id-ID"
                    @input="getData">
      </b-datepicker>
    </div>

    <bar-chart :series="barData.series" :chart-options="barData.chartOptions" v-if="!data.loading"/>
  </card-component>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import SgTab from "@/components/Sg/SgTab";
import BarChart from "@/components/Charts/BarChart";
import {mapActions, mapState} from "vuex";

export default {
  name: "BookChartDisease",
  components: {BarChart, SgTab, CardComponent},
  data: () => {
    return {
      activeTab: 'Sum of Death shrimp group by disease per month',
      monthlyDate: new Date(),
    }
  },
  computed: {
    ...mapState('farm', [
      'chart',
    ]),

    data() {
      switch (this.activeTab) {
        case 'Sum of Death shrimp group by disease per month':
          return this.chart.disease
        case 'Sum of Death shrimp group by province':
          return this.chart.death
      }
      return []
    },

    barData() {
      let data = this.data.data

      if (data && data.length) {
        // let from = this.$timestamp(this.graph_date[0])
        // let to = this.$timestamp(this.graph_date[1])

        return {
          series: [{
            name: 'Death',
            type: 'area',
            data: data.map(e => e.total || e.deaths)
          }],
          chartOptions: {
            chart: {
              height: 350,
              type: 'bar',
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            fill: {
              type: 'solid',
              opacity: 1,
            },
            labels: data.map(e => e.disease || e.city),
            markers: {
              size: 0
            },
            tooltip: {
              enabled: true,
              followCursor: true,
            }
          }
        }
      }
      return null
    },
  },
  created() {
    this.getData()
  },
  methods: {
    ...mapActions('farm', [
      'getDiseasePerMonth',
      'getDeathPerMonth',
    ]),

    getData() {
      let monthlyDate = this.$moment(this.monthlyDate)

      let month = monthlyDate.month() + 1
      let year = monthlyDate.year()

      switch (this.activeTab) {
        case 'Sum of Death shrimp group by disease per month':
          return this.getDiseasePerMonth({month, year})
        case 'Sum of Death shrimp group by province':
          return this.getDeathPerMonth({month, year})
      }
    },
  }
}
</script>
